import Image from 'gatsby-image/withIEPolyfill';
import React from 'react';

export default function ({ data, objectFit, className, ...other }: any) {
  if (!data?.localFile) return <div></div>;

  return data?.localFile?.childImageSharp?.fluid ? (
    <Image
      fluid={data?.localFile?.childImageSharp?.fluid}
      objectFit={objectFit}
      className={className}
      {...other}
    />
  ) : (
    <img
      src={data?.localFile?.publicURL}
      className={`${objectFit && `object-${objectFit}`} ${className}`}
      {...other}
    />
  );
}
